export default [
	// {
	// 	path: '/home',
	// 	name: 'home',
	// 	meta: {
	// 		title: '数据驾驶舱',
	// 		icon: 'iconfont icon-shujujiashicang',
	// 	},
	// 	// children: [
	// 	// 	{
	// 	// 		path: '/home/index',
	// 	// 		name: 'index',
	// 	// 		meta: {
	// 	// 			title: '巡诊驻派数据驾驶舱',
	// 	// 		},
	// 	// 	},
	// 	// ],
	// },

	{
		path: '/accountManager',
		name: 'accountManager',
		meta: {
			title: '用户管理',
			icon: 'iconfont icon-zhanghuguanli',
		},
		children: [
			{
				path: '/accountManager/countyAccount',
				name: 'countyAccount',
				meta: {
					title: '用户管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
	// {
	// 	path: '/performanceManager',
	// 	name: 'performanceManager',
	// 	meta: {
	// 		title: '绩效体系',
	// 		icon: 'iconfont icon-jigoutongji',
	// 	},
	// 	children: [
	// 		{
	// 			path: '/performanceManager/index',
	// 			name: 'index',
	// 			meta: {
	// 				title: '乡镇卫生院绩效评价',
	// 				icon: 'iconfont icon-fuzhiyemian',
	// 			},
	// 		},
	// 	],
	// },
	{
		path: '/staticTotalView',
		name: 'staticTotalView',
		meta: {
			title: '基础数据统计',
			icon: 'iconfont icon-shujutongji',
		},
		children: [
			{
				path: '/staticTotalView/userInfo',
				name: 'userInfo',
				meta: {
					title: '居民信息统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/familyInfo',
				name: 'familyInfo',
				meta: {
					title: '家庭信息统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/institutionalStatistics',
				name: 'institutionalStatistics',
				meta: {
					title: '机构统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},

			{
				path: '/staticTotalView/resourceStatic',
				name: 'resourceStatic',
				meta: {
					title: '资源统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			// {
			// 	path: '/staticTotalView/healthEqu',
			// 	name: 'healthEqu',
			// 	meta: {
			// 		title: '康养设备统计',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
			{
				path: '/staticTotalView/medicalStaffStatistics',
				name: 'medicalStaffStatistics',
				meta: {
					title: '医护人员统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/patrolStatistics',
				name: 'patrolStatistics',
				meta: {
					title: '巡诊统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/residentStatistics',
				name: 'residentStatistics',
				meta: {
					title: '驻派统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/childImmunity',
				name: 'childImmunity',
				meta: {
					title: '接种预防统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/publicEmergencies',
				name: 'publicEmergencies',
				meta: {
					title: '公共突发事件统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/staticTotalView/familyDoctor',
				name: 'familyDoctor',
				meta: {
					title: '家庭签约医生统计',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
	{
		path: '/staticManager',
		name: 'staticManager',
		meta: {
			title: '数据分析',
			icon: 'iconfont icon-shujufenxi',
		},
		children: [
			{
				path: '/staticManager/medicalStaffData',
				name: 'medicalStaffData',
				meta: {
					title: '医护人员数据分析',
				},
			},
			{
				path: '/staticManager/importPersonData',
				name: 'importPersonData',
				meta: {
					title: '重点人群数据分析',
				},
			},
			{
				path: '/staticManager/infectiousDiseaseData',
				name: 'infectiousDiseaseData',
				meta: {
					title: '传染病数据分析',
				},
			},
			{
				path: '/staticManager/healthEduData',
				name: 'healthEduData',
				meta: {
					title: '健康教育数据分析',
				},
			},
			{
				path: '/staticManager/receiveData',
				name: 'receiveData',
				meta: {
					title: '接转诊数据分析',
				},
			},
			{
				path: '/staticManager/inspectData',
				name: 'inspectData',
				meta: {
					title: '检查检验数据分析',
				},
			},
			// {
			// 	path: '/staticManager/makeAroundData',
			// 	name: 'makeAroundData',
			// 	meta: {
			// 		title: '巡诊数据分析',
			// 	},
			// },
			// {
			// 	path: '/staticManager/residencyData',
			// 	name: 'residencyData',
			// 	meta: {
			// 		title: '驻派分析',
			// 	},
			// },
			{
				path: '/staticManager/deviceData',
				name: 'deviceData',
				meta: {
					title: '康养设备数据分析',
				},
			},
		],
	},
	{
		path: '/systemManager',
		name: 'systemManager',
		meta: {
			title: '系统管理',
			icon: 'iconfont icon-shezhi',
		},
		children: [
			{
				path: '/systemManager/departmentList',
				name: 'departmentList',
				meta: {
					title: '科室字典',
				},
			},
			{
				path: '/systemManager/postList',
				name: 'postList',
				meta: {
					title: '岗位字典',
				},
			},
			{
				path: '/systemManager/titleList',
				name: 'titleList',
				meta: {
					title: '职称字典',
				},
			},
			{
				path: '/systemManager/dictionaryList',
				name: 'dictionaryList',
				meta: {
					title: '其他字典',
				},
			},
		],
	},
];
